//@ts-nocheck
import { useState } from "react";
import styled from "styled-components";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import emailjs from "@emailjs/browser";
import { useForm, useFieldArray } from "react-hook-form";
import "./App.css";

const incidentTypeOptions = [
  { label: "Brand", value: "Brand" },
  { label: "Bombhot", value: "Bombhot" },
  { label: "Förtal", value: "Förtal" },
  { label: "Hot", value: "Hot" },
  { label: "Misshandel", value: "Misshandel" },
  { label: "Ofredande", value: "Ofredande" },
  { label: "Sabotage", value: "Sabotage" },
  { label: "Skadegörelse", value: "Skadegörelse" },
  { label: "Trakasseri", value: "Trakasseri" },
  { label: "Störande av sammankonst", value: "Störande av sammankonst" },
  { label: "Övrigt", value: "Övrigt" },
];

function App() {
  const [showOptionalField, setShowOptionalField] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({});

  const sendEmail = (formData, e) => {
    const index = formData.type.indexOf("Övrigt");
    if (index >= 0) {
      //const { replace } = useFieldArray({ name: "test" });

      formData.type.splice(index, 1);
      formData.type.push(formData.incidentTypeOther);
      //setValue("type", formData.type);
    }

    console.log(e.target);

    emailjs
      .sendForm(
        "service_pgmm4vd",
        "template_w1yu0cf",
        e.target,
        "hzP3_bP99pRQRMEGG"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccessMessage(true);
          setShowOptionalField(false);
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const onChangeTypeOfIncident = (e: CheckboxChangeEvent) => {
    if (e.target.value === "Övrigt") {
      const prevVal = showOptionalField;
      setShowOptionalField(!prevVal);
    }
  };

  return (
    <div className="App">
      <Container>
        <Head>
          <Title>
            <mark>Incidentrapport - Sverigedemokraterna</mark>
          </Title>
          <span>
            Beskriv din incident. Utskriftsversion av formuläret finns{" "}
            <a
              target="_blank"
              href="https://drive.google.com/open?id=1-VrnxVfknKOt1OieEZBPkE_2oP1o-YfhqjArM19AZNgQM8QvzfQnXHwm1mp9uzM5sVWC0O_wYfV3dazr"
            >
              här
            </a>
            .
          </span>
        </Head>
        <form onSubmit={handleSubmit(sendEmail)}>
          <ItemContainer>
            <CheckboxGroup style={{ textAlign: "left" }}>
              <ItemLabel>
                Typ av incident
                <Asterisk />
              </ItemLabel>
              <br />
              {incidentTypeOptions.map((option) => {
                return (
                  <>
                    <input
                      type="checkbox"
                      {...register("type", { required: true })}
                      value={option.value}
                      onChange={onChangeTypeOfIncident}
                    />
                    <CheckboxLabel htmlFor={option.value}>
                      {option.label}
                    </CheckboxLabel>
                    <br />
                  </>
                );
              })}
              {errors.type && (
                <ItemError>Det här fältet är obligatoriskt</ItemError>
              )}
            </CheckboxGroup>
            {showOptionalField && (
              <>
                <ItemLabel></ItemLabel>
                <ItemInput
                  style={{ marginTop: "-1rem", paddingTop: 0 }}
                  {...register("incidentTypeOther", { required: true })}
                />
                {errors.when && (
                  <ItemError>Det här fältet är obligatoriskt</ItemError>
                )}
              </>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>
              E-postadress
              <Asterisk />
            </ItemLabel>
            <ItemInput
              type="email"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <ItemError>Det här fältet är obligatoriskt</ItemError>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>
              Vad har hänt?
              <Asterisk />
            </ItemLabel>
            <ItemTextArea {...register("description", { required: true })} />
            {errors.description && (
              <ItemError>Det här fältet är obligatoriskt</ItemError>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>
              När hände det? (datum och tidpunkt)
              <Asterisk />
            </ItemLabel>
            <ItemInput type="text" {...register("when", { required: true })} />
            {errors.when && (
              <ItemError>Det här fältet är obligatoriskt</ItemError>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>
              Var hände det? (adress)
              <Asterisk />
            </ItemLabel>
            <ItemInput type="text" {...register("where", { required: true })} />
            {errors.where && (
              <ItemError>Det här fältet är obligatoriskt</ItemError>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>
              Målsägande / Offer
              <Asterisk />
            </ItemLabel>
            <ItemInput
              type="text"
              {...register("victim", { required: true })}
            />
            {errors.victim && (
              <ItemError>Det här fältet är obligatoriskt</ItemError>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>
              Kontaktinformation
              <Asterisk />
            </ItemLabel>
            <ItemInput
              type="text"
              {...register("contact_info", { required: true })}
            />
            {errors.contact_info && (
              <ItemError>Det här fältet är obligatoriskt</ItemError>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>
              Kommunförening
              <Asterisk />
            </ItemLabel>
            <ItemInput
              type="text"
              {...register("forening", { required: true })}
            />
            {errors.forening && (
              <ItemError>Det här fältet är obligatoriskt</ItemError>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>
              Uppgiftslämnare (om annan än målsägande / offer)
            </ItemLabel>
            <ItemInput type="text" {...register("contact_name_other")} />
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Kontaktuppgifter (Uppgiftslämnare)</ItemLabel>
            <ItemInput type="text" {...register("contact_info_other")} />
          </ItemContainer>
          <h2>Uppgifter från polisen</h2>
          <ItemContainer>
            <ItemLabel>Ärendenummer</ItemLabel>
            <ItemInput type="text" {...register("police_number")} />
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Kontaktuppgifter</ItemLabel>
            <ItemInput type="text" {...register("police_contact")} />
          </ItemContainer>
          {/*  <ItemContainer>
            <ItemLabel>E-postadress</ItemLabel>
            <ItemInput type="email" {...register("police_email")} />
          </ItemContainer> */}
          {showSuccessMessage && (
            <SuccessMessage>
              <mark>
                Din incident har rapporterats, vi återkommer på mail inom kort!
              </mark>
            </SuccessMessage>
          )}
          <CustomButton type="submit" value="Skicka" />
        </form>
      </Container>
    </div>
  );
}

export default App;

const Container = styled.div`
  max-width: 600px;
  margin: auto;
  color: #222222;

  a {
    color: #ff9c00;
    font-weight: bold;
  }
`;

const ItemContainer = styled.div`
  background-color: #fafafa;
  padding: 1.5rem;
  border-radius: 0.3rem;
  width: calc(100% - 2rem);
  margin: 1rem auto;
`;

const ItemLabel = styled.label`
  color: #322667;
  font-weight: bold;
  font-size: 1.375rem;
  display: inline-block;
  width: 100%;
  text-align: left;
`;

const ItemInput = styled.input`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border-radius: 2px;
  transition: all 0.3s;
  border: 0;
  border-bottom: solid 1px #222222;
  padding-left: 0;
  background-color: transparent;

  ::selection,
  :focus-visible {
    outline: none;
  }
`;

const CustomButton = styled.input`
  background: #ffde00;
  border: none;
  border-radius: 0.6rem;
  color: #ff9c00;
  font-size: 1rem;
  font-weight: 700;
  max-width: 150px;
  padding: 0.5rem 1.5rem;
  width: 100%;
  margin-bottom: 1rem;

  :hover {
    cursor: pointer;
  }
`;

const CheckboxGroup = styled.fieldset`
  border: none;
  padding: 0;
`;

const ItemError = styled.span`
  color: red;
`;

const ItemTextArea = styled.textarea`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border-radius: 2px;
  transition: all 0.3s;
  border: 0;
  border-bottom: solid 1px #222222;
  padding-left: 0;
  background-color: transparent;
  font-family: "Ibiza22";

  ::selection,
  :focus-visible {
    outline: none;
  }
`;

const CheckboxLabel = styled.label`
  padding-left: 0.5rem;
`;

const Title = styled.h1`
  font-weight: bold;
  margin-top: 1rem;

  mark {
    color: #ff9c00;
    background-color: #ffde00;
  }
`;

const Asterisk = styled.span`
  ::before {
    content: " *";
    color: red;
  }
`;

const Head = styled.div`
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.span`
  width: 100%;
  display: inline-block;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.4rem;

  mark {
    color: #ff9c00;
    background-color: #ffde00;
  }
`;
